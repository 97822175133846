.research {
    margin: 0;
    padding: 0;
    font-family: 'Roboto', sans-serif;
}

.research-header-heading {
    background-image: url('../../../public/images/publication/publication.jpg');
    height: 80vh;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.research-header-heading .research-title {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 40px 150px;
    background: rgba(0, 0, 0, 0.5);
    color: #f2f2f2;
    border-radius: 5px;
    text-align: center;
}


.research-header-heading .research-title h1 {
    text-transform: uppercase;
    letter-spacing: 1px;
    line-height: 1.15;
}

.research-list-section {
    padding: 30px 8%;
}

.research-list-section .research-list-header {
    font-size: 2em;
    text-align: center;
}

.research-list-section .research-list-desc {
    padding: 0 200px;
}

.research-list {
    padding: 10px 50px;
}

.research-listitem {
    display: grid;
    grid-template-columns: 1fr;
    place-items: center;
    margin: 10px 100px;
}

.research-listitem .research-paper {
    padding: 20px;
    margin: 20px 0;
    width: 80%;
    background-color: #dce6f3;

}

.research-paper h3,
.research-paper p {
    padding: 20px 0;
}

.research-paper a {
    text-decoration: none;
    color: black;
    cursor: pointer;
}

.research-paper a:hover {
    text-decoration: underline;
}

.research-old-publication {
    list-style-type: none;
    display: flex;
    flex-direction: column;
    padding: 50px 200px;

}


@media screen and (max-width: 1024px) {
    .research-list-section .research-list-desc {
        padding: 0 50px;
    }
}
@media screen and (max-width: 768px) {
    .research-header-heading .research-title {
        padding: 20px 50px;
    }   
    .research-old-publication {
        padding: 10px 20px;
    }
    .research-list {
        padding: 10px;
    }
    .research-listitem {
        margin: 10px 20px;
    }
}

@media screen and (max-width: 600px) {
    .research-header-heading {
        height: 60vh;
    }
}

@media screen and (max-width: 500px) {
    .research-header-heading {
        height: 50vh;
    }

    .research-header-heading .research-title {
        transform: translate(-50%, -80%);

    }
}