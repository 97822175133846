.navbar {
    display: flex;
    background: #eae4e3;
    justify-content: space-between;
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 999;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 20px, rgba(0, 0, 0, 0.5) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;

        /* padding: 20px 50px; */
    /* background: #f2f2f2; */
    /* border-bottom: 8px solid rgb(198, 13, 13); */
    /* border-bottom: 8px solid #7da5dd; */
}

.navbar-logosection {
    height: auto;
    padding: 20px 50px;
}

.navbar-logosection .navbar-brand {
    display: flex;
}

.navbar-logosection .navbar-brand div{
    margin: 0 10px;
}

.navbar-logosection .navbar-brand .navbar-lab-name {
    color: #222;
    align-self: center;
    border-left: 2px solid #222;
    padding: 0 20px;
    font-size: 1.25em;
    font-weight: 700;
    text-transform: uppercase;
    font-family: 'Courgette', cursive;
    letter-spacing: 1px;
}

.navbar-logosection .navbar-brand a {
    text-decoration: none;
}

.navbar-logosection img.navbar-logo-img {
    width: 64px;
}

.navbar .navbar-nav {
    /* padding: 50px 0 0 0; */
        display: flex;
        justify-content: center;
        align-items: center;
}

.navbar-nav .navbar-list {
    list-style-type: none;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-inline-start: 0;
    padding: 0 20px;
}

.navbar-nav ul li a {
    text-decoration: none;
    color: #222;
    padding: 15px 30px;
    /* background: #444; */
    margin: 0 1px;
    transition: 0.2s all;
}

.navbar-nav ul li a:hover {
    text-decoration: underline;
}

.burger-link {
    color: black;
}

.burger-item:hover {
    background-color: #ddd;
}