.cafi-detail-section {
    padding: 3% 20%;
}
.cafi-detail-img {
    width: 100%;
    /* height: 500px; */
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.cafi-detail-typo {
    padding: 20px 0;
}

.cafi-author {
    display: flex;
    align-items: center;
    gap: 10px;
}

@media screen and (max-width: 768px) {
    .cafi-detail-section {
        padding: 3% 10%;
    }
}

@media screen and (max-width: 500px) {
    .cafi-detail-section h4 {
        font-size: 1.35em;
    }
    .cafi-detail-section h6 {
        font-size: 0.85em;
    }

    .cafi-detail-img {
        height: 50vh;
    }

    .cafi-detail-section p {
        font-size: 0.95em;
    }


}