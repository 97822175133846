.footer {
    display: flex;
    flex-direction: column;
    background: black;
}

.footer .footer-nav-section .footer-nav-list {
    list-style-type: none;
    display: flex;
    justify-content: center;
    padding: 50px;

}

.footer .footer-nav-section .footer-nav-list li a {
    text-decoration: none;
    color: #f2f2f2d3;
    padding: 0 20px;
    border-right: 1px solid #f2f2f2d3;
}

.footer .footer-nav-section .footer-nav-list li a:hover {
    text-decoration: underline;

}

.footer-img-section {
    display: grid;
    place-items: center;
}

.footer-img-section img {
    width: 50%;
}

.footer-nav-listitem .footer-firstlink {
    border-left: 1px solid #f2f2f2d3;
}

@media screen and (max-width: 600px) {
    .footer .footer-nav-section .footer-nav-list {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 10px;
    }

    .footer-nav-listitem {
        justify-self: end;
    }

    .footer-nav-listitem .footer-firstlink {
        border-left: none;
    }


}

@media screen and (max-width: 500px) {
    .footer .footer-nav-section .footer-nav-list {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 10px;
    }

    .footer-nav-listitem {
        justify-self: end;
    }

    .footer-nav-listitem .footer-firstlink {
        border-left: none;
    }


}