.cafi {
    margin: 0;
    padding: 0;
    font-family: 'Roboto', sans-serif;
}

.cafi-header-heading {
    background-image: url('../../../public/images/landingimg.jpg');
    height: 80vh;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.cafi-header-heading .cafi-title {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 40px 150px;
    background: rgba(0, 0, 0, 0.5);
    color: #f2f2f2;
    border-radius: 5px;
    text-align: center;
}


.cafi-header-heading .cafi-title h1 {
    text-transform: uppercase;
    letter-spacing: 1px;
    line-height: 1.15;
}

.cafi-image {
    display: grid;
    place-items: center;
    padding-bottom: 30px;
}

.cafi-image img {
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.cafi-list-section {
    padding: 30px;
    padding-bottom: 0;
}

.cafi-list-section .cafi-list-header {
    font-size: 2em;
    text-align: center;
}

.cafi-list-section .cafi-list-desc {
    padding: 20px 20%;
    padding-top: 0;
}

.cafi-typo {
    padding: 10px 0;
}

.cafi-list {
    padding: 10px 50px;
}

.cafi-listitem {
    display: grid;
    grid-template-columns: 1fr;
    place-items: center;
    margin: 10px 100px;
}

.cafi-listitem .cafi-paper {
    padding: 20px;
    margin: 20px 0;
    width: 80%;
    background-color: #dce6f3;

}

.cafi-paper h3,
.cafi-paper p {
    padding: 20px 0;
}

.cafi-paper a {
    text-decoration: none;
    color: black;
    cursor: pointer;
}

.cafi-paper a:hover {
    text-decoration: underline;
}

.cafi-old-publication {
    list-style-type: none;
    display: flex;
    flex-direction: column;
    padding: 50px 200px;

}

.cafi-swiper-blog {
    padding: 20px;
    /* padding-left: 100px; */
}
/* .swiper-button-prev, .swiper-button-next {
    color: #f2f2f2;
} */

.cafi-link {
    transition: 0.3s ease;
}

.cafi-link:hover {
    filter: brightness(80%);
}

@media screen and (max-width: 768px) {
    .cafi-image img {
        width: 425px;
    }
    .cafi-list-section .cafi-list-desc {
        padding: 20px 10%;
    }

    .cafi-header-heading .cafi-title {
        padding: 20px 50px;
    }
}

@media screen and (max-width: 600px) {
    .cafi-header-heading {
        height: 60vh;
    }
}

@media screen and (max-width: 500px) {
    .cafi-header-heading {
        height: 50vh;
    }

    .cafi-header-heading .cafi-title {
        transform: translate(-50%, -80%);

    }
}