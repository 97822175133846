.teaching {
    margin: 0;
    padding: 0;
    font-family: 'Roboto', sans-serif;
}

.teaching-header-heading {
    background-image: url('../../../public/img/index/slider/sample-img/Human_Chimp.jpg');
    height: 80vh;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.teaching-header-heading .teaching-title {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 40px 150px;
    background: rgba(0, 0, 0, 0.5);
    color: #f2f2f2;
    border-radius: 5px;
    text-align: center;
}


.teaching-header-heading .teaching-title h1 {
    text-transform: uppercase;
    letter-spacing: 1px;
    line-height: 1.15;
}

.teaching-list-section {
    padding: 50px 10%;
}

.teaching-list-section .teaching-list-header {
    font-size: 2em;
    text-align: center;
}

.teaching-list-section .teaching-list-desc {
    padding: 0 10%;
}

.teaching-list {
    padding: 0;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 30px 30px;
}

.teaching-listitem {
    display: grid;
    grid-template-columns: 1fr;
    place-items: center;
}

.teaching-listitem .teaching-paper {
    padding: 20px;
    /* margin: 20px 0; */
    /* width: 80%; */
    background-color: #dce6f3;
    height: 220px;

}

.teaching-paper h3,
.teaching-paper p {
    padding: 20px 0;
}

.teaching-paper a {
    text-decoration: none;
    color: black;
    cursor: pointer;
}

.teaching-paper a:hover {
    text-decoration: underline;
}

@media screen and (max-width: 1024px) {
    .teaching-list {
            padding: 0;
            display: grid;
            grid-template-columns: 1fr;
            gap: 30px 30px;
        }
}

@media screen and (max-width: 768px) {
    .teaching-header-heading .teaching-title {
        padding: 20px 50px;
    }


}

@media screen and (max-width: 600px) {
    .teaching-header-heading {
        height: 60vh;
    }
}

@media screen and (max-width: 500px) {
    .teaching-header-heading {
        height: 50vh;
    }

    .teaching-header-heading .teaching-title {
        transform: translate(-50%, -80%);

    }

    .teaching-listitem .teaching-paper {
        height: auto;
    }

}