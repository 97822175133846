.about {
    display: flex;
    justify-content: center;
    padding: 50px 300px;
    font-family: 'Roboto', san-serif;
}

.about p {
    color: #333;
}

.about .about-img-section, .about .about-bio-section {
    margin: 50px;
}

.about-img {
    width: 350px;
    height: auto;
    /* border-top: 5px solid rgb(198, 13, 13); */
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-radius: 5px;

}

.about-bio-section {
    display: flex;
    flex-direction: column;
}

.about-uno-section {
    display: flex;
    align-items: center;
    padding: 20px;
    border-top: 2px solid rgb(198, 13, 13);
    border-bottom: 2px solid rgb(198, 13, 13);
}

.about-uno-section .about-uno-img {
    width: 75px;
    height: 70px;
}

.about-uno-section .about-uno-img, .about-uno-section .about-uno-dept {
    margin: 0 10px;
}

.about-bio-section .about-name-section {
    font-size: 0.95em;
    padding: 20px;
    border-bottom: 1px dashed rgba(0,0,0,0.5);
    
}

.about-bio-section .about-name-section h2 {
    margin: 5px 0;
}

.about-bio-section .about-biography-section {
    font-size: 0.95;
    padding: 10px 20px;
}

.about-bio-section .about-biography-section h3 {
    margin: 5px 0;
}

.about-bio-section .about-biography-section p {
    line-height: 1.2;
}

@media screen and (max-width: 1440px) {
    .about {
        padding: 100px 100px;
    }
}

@media screen and (max-width: 768px) {
    .about {
        padding: 50px 50px;
    }
    .about-img {
        width: 250px;
    }
    .about .about-img-section, .about .about-bio-section {
        margin: 30px;
    }
    .about-bio-section .about-uno-section h4 {
        font-size: 1.15em;
    }
}

@media screen and (max-width: 500px) {
    .about {
        padding: 50px 10px;
        display: grid;
    }

    .about-img {
        width: 200px;
        
    }

    .about .about-img-section {
        justify-content: center;
        text-align: center;
    }

    .about .about-img-section,
    .about .about-bio-section {
        margin: 5px;
    }

    .about-bio-section .about-uno-section h4 {
        font-size: 1.15em;
    }

    .about-uno-section {
        padding: 20px 0;
    }
}