.members {
    margin: 0;
    padding: 0;
    font-family: 'Roboto', sans-serif;
}

.members-header-heading {
    background-image: url('../../../public/images/team.jpeg');
    height: 80vh;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.members-header-heading .members-title {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 40px 150px;
    background: rgba(0, 0, 0, 0.5);
    color: #f2f2f2;
    border-radius: 5px;
    text-align: center;
}


.members-header-heading .members-title h1 {
    text-transform: uppercase;
    letter-spacing: 1px;
    line-height: 1.15;
}

@media screen and (max-width: 768px) {
    .members-header-heading .members-title {
        padding: 20px 50px;
    }
}

@media screen and (max-width: 600px) {
    .members-header-heading {
        height: 60vh;
    }
}
@media screen and (max-width: 500px) {
    .members-header-heading {
        height: 50vh;
    }

    .members-header-heading .members-title {
        transform: translate(-50%, -80%);

    }
}

