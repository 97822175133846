.hero-image {
    height: 100vh;
}

.hero-text-section {
    position: absolute;
    bottom: 100px;
    left: 0;
    width: 50%;
    background: rgba(0,0,0,0.5);
    color: #fff;
    padding: 20px;
}

.hero-text-section .hero-title {
    font-size: 1.5em;
    font-weight: 700;
    font-family: 'Roboto', sans-serif;
}

@media screen and (max-width: 768px) {

    .hero-text-section {
        width: 70%;
    }
    .hero-text-section .hero-title {
        font-size: 1.25em;
    }
}